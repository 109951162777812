<template>
  <div v-if="solarPanel" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="solarPanel">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('manufacturer')"
            label-for="manufacturer"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="solarPanel.manufacturer"
              type="text"
              :placeholder="$t('manufacturer')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('type')"
            label-for="type"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="solarPanel.type" type="text" :placeholder="$t('type')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('power')"
            label-for="power"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="solarPanel.power" type="text" :placeholder="$t('power')" />
          </b-form-group>
          <legend>
            <strong>Dokumente</strong>
          </legend>

          <DropzoneUpload
            v-if="!isNew"
            :complete="isAttachmentUploadComplete"
            :parentId="solarPanel.id"
            :uploadCallback="uploadSolarPanelAttachment"
            v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
          />

          <AttachmentList
            :attachments="solarPanel.attachments"
            v-on:attachment-list:delete="onDeleteSolarPanelAttachment"
          ></AttachmentList>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="solarPanel"
            modelEditRoute="SolarPanelEditPage"
            modelListRoute="SolarPanelsPage"
            modelRouteParamName="solarPanelNumber"
            :updateCallback="updateSolarPanel"
            :createCallback="createSolarPanel"
            :deleteCallback="deleteSolarPanel"
            :fetchCallback="fetchSolarPanels"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import { yesNo } from '@/helpers/enums';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'SolarPanelEditPage',
  components: {
    Widget,
    Breadcrumbs,
    DropzoneUpload,
    AttachmentList,
    ButtonBar,
  },
  props: {
    solarPanelNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
      isAttachmentUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getSolarPanel', 'getSolarPanelByNumber']),
    buttons() {
      return {
        showDeleteButton: this.solarPanel && this.solarPanel.number !== '_new' ? true : false,
      };
    },
    optionsYesNo() {
      return yesNo();
    },
    solarPanel() {
      return this.getSolarPanelByNumber(this.number);
    },
    isNew() {
      return this.solarPanel.id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('solarPanel'), route: { name: 'SolarPanelsPage' } },
        {
          name: this.solarPanel.number
            ? '"' + this.solarPanel.type + '"' + ' bearbeiten'
            : 'erstellen',
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initSolarPanel',
      'fetchSolarPanels',
      'fetchSolarPanelByNumber',
      'updateSolarPanel',
      'createSolarPanel',
      'deleteSolarPanel',
      'deleteSolarPanelAttachment',
      'uploadSolarPanelAttachment',
    ]),
    isDisabled(e) {
      e.preventDefault();
    },
    onDeleteSolarPanelAttachment(event) {
      this.deleteSolarPanelAttachment({
        solarPanelId: this.solarPanel.id,
        attachmentId: event.attachmentId,
      });
    },
  },
  async mounted() {
    if (!this.solarPanel && this.solarPanelNumber) {
      this.number = this.solarPanelNumber;
      this.fetchSolarPanelByNumber(this.number);
    } else {
      this.initSolarPanel();
      this.number = '_new';
    }
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadSolarPanelAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.solarPanelContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
</style>
